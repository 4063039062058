.react-date-picker__wrapper {
    border: 0;
}

.react-date-picker__inputGroup {
    color: #808080;
}

.react-date-picker__button svg {
    stroke: #808080;
}

.stepper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step.clickable {
    cursor: pointer;
}

.step-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    z-index: 2;
}

.step-name {
    font-size: 14px;
}

.active .step-number {
    background-color: #007bff;
    color: #fff;
}

.complete .step-number {
    background-color: #28a745;
    color: #fff;
}

.progress-bar {
    position: absolute;
    top: 25%;
    left: 0;
    height: 4px;
    background-color: #ccc;
}

.progress {
    height: 100%;
    background-color: #28a745;
    transition: 0.2s ease;
}

.sidebar-content {
    padding-right: 0 !important;
    margin-right: 0 !important;
}

.navbar .form-control {
    max-width: 300px;
}